import { useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import Colors from 'colors.json'
import { InputField } from 'components/InputField'
import { TextareaField } from 'components/TextareaField'
import Button from 'components/Button'
import {
  CampaignCreateFormSchemas,
  CampaignCreateFormTypes,
} from 'schemas/campaign-schemas'
import { useCampaignCreate } from './use-campaign-create'

export const CreateNewCampaignForm = ({
  onCloseModal,
}: {
  onCloseModal: () => void
}) => {
  const formDefaultValues: CampaignCreateFormTypes = {
    campaign_id: '',
    code: '',
    external_reference: '',
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    status: '',
  }

  const form = useForm<CampaignCreateFormTypes>({
    resolver: zodResolver(CampaignCreateFormSchemas),
    defaultValues: formDefaultValues,
  })

  const {
    mutate: mutateCampaignCreate,
    isSuccess: isSuccessCampaignCreate,
    isError: isErrorCampaignCreate,
    isLoading: isLoadingCampaignCreate,
  } = useCampaignCreate()

  const onSubmit = useCallback(
    async (formData: CampaignCreateFormTypes) => {
      mutateCampaignCreate({ formData })
      return
    },
    [mutateCampaignCreate]
  )

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
      <div className="mb-4">
        <Controller
          control={form.control}
          name="campaign_id"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="input"
              name="campaign_id"
              label="Campaign ID"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={form.control}
          name="code"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="input"
              name="code"
              label="Campaign Code"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={form.control}
          name="external_reference"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="input"
              name="external_reference"
              label="External Reference"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={form.control}
          name="name"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="input"
              name="name"
              label="Campaign Name"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={form.control}
          name="description"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextareaField
              name="description"
              label="Description"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          control={form.control}
          name="startDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="date"
              name="startDate"
              label="Start Date"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>

      <div className="mb-4">
        <Controller
          control={form.control}
          name="endDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="date"
              name="endDate"
              label="End Date"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>

      <div className="mb-4">
        <Controller
          control={form.control}
          name="status"
          render={({ field: { onChange, onBlur, value } }) => (
            <InputField
              type="input"
              name="status"
              label="Status"
              containerClassName="w-full"
              defaultValue={value}
              onChange={onChange}
            />
          )}
        />
      </div>

      <div className="flex justify-end pb-4 pl-4 pr-4">
        <Button
          type="submit"
          className="mr-2 rounded bg-primary"
          disabled={isLoadingCampaignCreate}
        >
          <span className="tracking-wider font-bold">
            {isLoadingCampaignCreate ? 'Submitting ...' : 'Submit'}
          </span>
        </Button>
        <Button
          className="bg-white border rounded border-primary"
          onClick={onCloseModal}
          style={{ color: Colors.primary }}
        >
          <span className="tracking-wider font-bold">Cancel</span>
        </Button>
      </div>
    </form>
  )
}
