import { useCallback, useState } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import Modal from 'components/Modal'
import { CreateNewCampaign } from './campaign-list-manual-tx/create-new-campaign'

export const CampaignListActionsModal = () => {
  const [isShowCampaignCreateModal, isShowCampaignCreateModalSet] =
    useState(true)

  const onOpenModal = useCallback(() => {
    isShowCampaignCreateModalSet(true)
  }, [isShowCampaignCreateModalSet])

  const onCloseModal = useCallback(() => {
    isShowCampaignCreateModalSet(false)
  }, [isShowCampaignCreateModalSet])

  return (
    <>
      <button
        className="px-3 py-1 mx-1 border rounded-md border-primary text-primary"
        onClick={onOpenModal}
        type="button"
      >
        <Icon icon={faPlus} className="mr-2" />
        <span className="tracking-wider font-light">Create New Campaign</span>
      </button>

      {/* MODAL */}
      <Modal
        title="Create New Campaign"
        open={isShowCampaignCreateModal}
        onClose={onCloseModal}
        noFooter
      >
        <CreateNewCampaign onCloseModal={onCloseModal} />
      </Modal>
    </>
  )
}
