import React from 'react'
import { useParams } from 'react-router-dom'
import Colors from 'colors.json'

import DatetimePickerInput from 'components/DatetimePickerInput'
import Input from 'components/Input'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown'
import { Form, Formik } from 'formik'

import { icashDonationTxSchemas } from 'schemas/icashDonationTxSchemas'
import { useIcashDonationTx } from './useIcashDonationTx'
import MerchantLookupTx from 'views/MemberProfile/ManualTx/MerchantLookupTx'
import { useMemberTXReasons } from './useMemberTXReasons'

const Index: React.FC<{ onCloseModal: () => void }> = ({
  onCloseModal,
}): React.ReactElement => {
  // STATES
  const { traderId } = useParams()
  const { membersTXReasonsOptions, memberTXReasonsModStatus } =
    useMemberTXReasons()
  const {
    isLoading,
    initialValues,
    isMerchantLookupToggle,
    handleIsMerchantLookupToggle,
    handleSubmitIcashDonationTx,
    handleClearIcashDonationTx,
  } = useIcashDonationTx(onCloseModal)

  if (isLoading) return <></>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={icashDonationTxSchemas}
      onSubmit={handleSubmitIcashDonationTx}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => {
        return (
          <Form className="flex flex-col">
            <div className="mb-4">
              <DatetimePickerInput
                title={'Transaction Date'}
                setFieldName="transactionDate"
                setFieldValue={setFieldValue}
              />
              {/* <MerchantLookupTx
                isMerchantLookupToggle={true}
                setFieldValue={setFieldValue}
              /> */}
              {/* <Input
                name="ident"
                label="To Card Ident"
                containerClassName="w-full"
              /> */}

              <Input
                name="creditValue"
                label="Credit Value"
                required
                containerClassName="w-full"
              />

              <div className="flex  gap-4 items-center">
                <Input
                  name="ident"
                  label="Card Ident"
                  containerClassName="w-full"
                />
                <Input
                  name="externalTransactionId"
                  label="External Trans ID"
                  // disabled
                  containerClassName="w-full"
                />
              </div>
              <div className="flex gap-4 items-center">
                <Input
                  type="number"
                  name="transactionValue"
                  label="Transaction Value"
                  disabled
                  containerClassName="w-full"
                />
                <Input
                  name="loyaltyValue"
                  label="Loyalty Value"
                  disabled
                  containerClassName="w-full"
                />
              </div>
              <div className="flex gap-4 items-center">
                <Input
                  name="storeId"
                  label="Store Trader ID"
                  disabled
                  containerClassName="w-full"
                />
                <Input
                  name="deviceCode"
                  label="Device Code"
                  disabled
                  containerClassName="w-full"
                />
              </div>
              <Dropdown
                title="Transaction Reason"
                options={membersTXReasonsOptions}
                className="w-full tracking-wider mb-4"
                borderColor="#C4C4C4"
                onSelect={(data) =>
                  setFieldValue('reasonId', String(data.value))
                }
              />
              <Input
                type="input"
                name="traceNumber"
                label="Trace No."
                // required
                containerClassName="w-full"
              />
              <Input
                type="input"
                name="comments"
                label="Comment"
                // required
                containerClassName="w-full"
              />
            </div>

            <footer className="flex justify-end pb-4 pl-4 pr-4">
              <Button
                type="submit"
                className="mr-2 rounded bg-primary"
                disabled={isSubmitting}
              >
                <span className="tracking-wider font-bold">Submit</span>
              </Button>
              <Button
                className="bg-white border rounded border-primary"
                onClick={onCloseModal}
                style={{ color: Colors.primary }}
              >
                <span className="tracking-wider font-bold">Cancel</span>
              </Button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Index
