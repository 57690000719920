import { z as zod } from 'zod'

import { DefaultSchemas } from './defaultSchemas'

export const CampaignDataSchemas = zod.object({
    campaign_id: zod.string().optional(),
    code: zod.string(),
    external_reference: zod.string(),
    name: zod.string(),
    description: zod.string(),
    startDate: zod.string(),
    endDate: zod.string(),
    status: zod.string(),
})

export const CampaignParamsSchemas = zod.object({
})

export const CampaignListTableSchemas = zod.object({
    tableData: CampaignDataSchemas.array()
})

export const CampaignCreateFormSchemas = zod.object({
    campaign_id: zod.string().optional(),
    code: zod.string(),
    external_reference: zod.string(),
    name: zod.string(),
    description: zod.string(),
    startDate: zod.string(),
    endDate: zod.string(),
    status: zod.string(),
})

export const CampaignAPISchemas = DefaultSchemas.merge(zod.object({
    data: CampaignDataSchemas.array()
}));

export type CampaignCreateFormTypes = zod.infer<typeof CampaignCreateFormSchemas>;
export type CampaignListTableTypes = zod.infer<typeof CampaignListTableSchemas>;
export type CampaignParamsTypes = zod.infer<typeof CampaignParamsSchemas>;
export type CampaignDataTypes = zod.infer<typeof CampaignDataSchemas>;
export type CampaignAPITypes = zod.infer<typeof CampaignAPISchemas>;