import { useQueryClient, useMutation } from 'react-query'

import { CampaignCreateFormTypes } from "schemas/campaign-schemas";
import { postCampaignCreate } from "api/campaign";
import { QUERY_KEYS } from "constants/querykeys";

export const useCampaignCreate = () => {
  const queryClient = useQueryClient();
  
  const mutationFn = (mutationData: { formData: CampaignCreateFormTypes }) => {
    const { formData } = mutationData;
    return postCampaignCreate(formData);
  };

  const mutation = useMutation({
    mutationFn,
    onError: (error: string) => {
      console.log(error, "error");
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CAMPAING_LIST],
      });
    },
    // onSettled(data, error, variables, context) {
    //   // console.log(data, " success - settled");
    //   // console.log(error, " error - settled");
    // },
  });

  return mutation;
};
