import { DropDownItem } from 'types/components'

export const MEMOSNOTES_TYPE_OPTIONS: DropDownItem[] = [
  { label: 'All', value: '' },
  { label: 'Email', value: 'Email' },
  { label: 'Call', value: 'Call' },
  { label: 'App', value: 'App' },
  { label: 'THOR', value: 'THOR' },
  { label: 'Online Msg', value: 'Online Msg' },
  { label: 'Fraud Review', value: 'Fraud Review' },
  { label: 'Proactive Support', value: 'Proactive Support' },
]

export const NOTE_TYPE_OPTIONS: DropDownItem[] = [
  { label: '---', value: '0' },
  { label: 'Call', value: '1' },
  { label: 'Email', value: '2' },
  { label: 'App', value: '3' },
  { label: 'THOR', value: '4' },
  { label: 'Online Msg', value: '5' },
  { label: 'Fraud Review', value: '6' },
  { label: 'Proactive Support', value: '7' },
]

export const MEMOSNOTES_LEVEL_OPTIONS: DropDownItem[] = [
  { label: 'All', value: '' },
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
]

export const MEMBERSHIPS_PERIOD_OPTIONS: DropDownItem[] = [
  { label: 'All', value: '' },
  { label: 'Active in period', value: '0' },
  { label: 'Started in period', value: '1' },
  { label: 'Ended in period', value: '2' },
]

export const MEMBERSHIPS_GROUP_OPTIONS: DropDownItem[] = [
  { label: 'All', value: '' },
  { label: 'Activated Promo Groups', value: '0' },
  { label: 'App Send Segments', value: '1' },
]

export const MEMBERSHIPS_CLASSTYPE_OPTIONS: DropDownItem[] = [
  { label: 'All', value: 'all_groups' },
  { label: 'Roi/ Ni Club Orange  - App Launch', value: 'group_1' },
  { label: 'Easy Campaign 1 New Features Testing', value: 'group_2' },
]

export const CHANNELS_FILTERS: DropDownItem[] = [
  { label: 'All', value: '0' },
  { label: 'Email', value: '1' },
  // { label: 'Postal', value: '2' },
  // { label: 'SMS', value: '3' },
  { label: 'Push', value: '4' },
]

export const STATUS_FILTERS: DropDownItem[] = [
  { label: 'All', value: '0' },
  { label: 'Sent', value: '1' },
  { label: 'Ready', value: '2' },
  { label: 'Error', value: '3' },
  { label: 'Canceled', value: '4' },
]

export const NOTE_TYPE_FILTERS: DropDownItem[] = [
  { label: 'All', value: 'all' },
  { label: 'Email', value: 'email' },
  { label: 'Call', value: 'call' },
]

export const NOTE_LEVEL_OPTIONS: DropDownItem[] = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
]

export const PRIORITY_LEVEL_FILTERS: DropDownItem[] = [
  { label: 'All', value: 'all' },
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
]

export const PRIORITY_LEVEL_OPTIONS: DropDownItem[] = [
  { label: 'Low', value: 'low' },
  { label: 'Medium', value: 'medium' },
  { label: 'High', value: 'high' },
]

export const VOUCHER_STATUS_FILTERS: DropDownItem[] = [
  { label: 'All', value: '0' },
  { label: 'Active', value: '1' },
  { label: 'Expired', value: '2' },
  { label: 'Redeemed', value: '3' },
  { label: 'Archived', value: '4' },
]

export const PERIOD_FILTERS: DropDownItem[] = [
  { label: 'All', value: 'all_period' },
  { label: 'Active in period', value: 'option_0' },
  { label: 'Started in period', value: 'option_1' },
  { label: 'Ended in period', value: 'option_2' },
]

export const CLASSTYPE_FILTERS: DropDownItem[] = [
  { label: 'All', value: 'all_groups' },
  { label: 'Roi/ Ni Club Orange  - App Launch', value: 'group_1' },
  { label: 'Easy Campaign 1 New Features Testing', value: 'group_2' },
]

export const MERGE_OPTIONS: DropDownItem[] = [
  { label: 'MERGE - EXISTING CARD', value: '0' },
  { label: 'MERGE - NEW CARD', value: '1' },
]

export const HAMPSTA_ADDREPLACE_OPTIONS: DropDownItem[] = [
  { label: 'ADD CARD', value: '0' },
  { label: 'REPLACE CARD', value: '1' },
]

export const HAMPSTA_CARDCOLOR_OPTIONS: DropDownItem[] = [
  { label: 'Default', value: 'default' },
  { label: 'Orange', value: 'orange' },
  { label: 'Pink', value: 'pink' },
  { label: 'Black', value: 'black' },
  { label: 'Gold', value: 'gold' },
  { label: 'MB - White', value: 'MB WHITE' },
  { label: 'MB - Black', value: 'MB_BLACK' },
]

export const IDENT_OPTIONS: DropDownItem[] = [
  { label: 'Add Card', value: '0' },
  { label: 'Replace Card', value: '1' },
]

export const BANKSTATUS_OPTIONS: DropDownItem[] = [
  { label: 'Active', value: '1' },
  { label: 'Inactive', value: '2' },
  { label: 'Cancelled', value: '3' },
]

export const BANK_ACCOUNT_STATUS: DropDownItem[] = [
  { label: 'Active', value: '1' },
  { label: 'Inactive', value: '-1' },
]

export const CARDCOLOR_OPTIONS: DropDownItem[] = [
  { label: 'Default', value: 'default' },
  { label: 'Orange', value: 'orange' },
  { label: 'Pink', value: 'pink' },
  { label: 'Black', value: 'black' },
  { label: 'Gold', value: 'gold' },
  { label: 'MB - White', value: 'MB WHITE' },
  { label: 'MB - Black', value: 'MB_BLACK' },
]

export const IDENT_TYPE_FILTERS: DropDownItem[] = [
  { label: 'All', value: '0' },
  { label: 'Card ISO / Virtual Cards', value: '1' },
  { label: 'Card Non-ISO', value: '2' },
  { label: 'Login', value: '3' },
  { label: 'Phone No', value: '4' },
  { label: 'Email', value: '5' },
]

export const IDENT_STATUS_FILTERS: DropDownItem[] = [
  { label: 'All', value: '0' },
  { label: 'In Use', value: '1' },
  { label: 'On Hold', value: '2' },
  { label: 'Cancelled', value: '3' },
  { label: 'Stolen', value: '4' },
  { label: 'Lost', value: '5' },
  { label: 'On Hold - Virtualized', value: '6' },
  { label: 'On Hold - Replaced', value: '7' },
]

export const IDENT_STATUS_OPTIONS: DropDownItem[] = [
  { value: '1', label: 'In Use' },
  { value: '2', label: 'On Hold' },
  { value: '3', label: 'Cancel' },
]

export const TRANSACTION_STATUS: DropDownItem[] = [
  { label: 'All', value: 'all_types' },
  { label: 'Regular', value: 'type_1' },
  { label: 'Refund', value: 'type_2' },
  { label: 'Reversal', value: 'type_3' },
]

export const TRANSACTION_TYPES: DropDownItem[] = [
  { label: 'All', value: '0' },
  { label: 'Purchase', value: '1' },
  { label: 'Purchase Reversal', value: '-1' },
  { label: 'Purchase Refund', value: '11' },
  { label: 'Zero Value Purchase', value: '100' },
  { label: 'E-Voucher Redemption', value: '76' },
  { label: 'Purchase/Loyalty Redemption', value: '2' },
  { label: 'Purchase/Loyalty Redemption Reversal', value: '-2' },
  { label: 'Purchase/Loyalty Redemption Refund', value: '12' },
  { label: 'Loyalty Redemption', value: '80' },
  { label: 'Loyalty Redemption Reversal', value: '-80' },
  { label: 'iCash Topup', value: '9' },
  { label: 'iCash Topup Reversal', value: '-9' },
  { label: 'Special iCash Refund', value: '19' },
  { label: 'Special iCash Refund Reversal', value: '-19' },
  { label: 'Credit Allocation', value: '20' },
  { label: 'Credit Allocation refund', value: '30' },
  { label: 'Purchase/iCash Redemption', value: '4' },
  { label: 'Purchase/iCash Redemption Reversal', value: '-4' },
  { label: 'iCash Donation', value: '24' },
  { label: 'iCash Donation Refund', value: '-24' },
]

export const MERCHANT_LOOKUP_TYPES: DropDownItem[] = [
  { label: 'Trader Id', value: 'traderId' },
  { label: 'Merchant Name', value: 'merchantName' },
  { label: 'Merchant Code', value: 'merchantCode' },
  { label: 'External Id', value: 'externalId' },
]

export const PAYMENT_FREQUENCY: DropDownItem[] = [
  { label: 'Weekly', value: '0' },
  { label: 'Monthly', value: '1' },
  { label: 'Yearly', value: '2' },
]

export const DIRECT_PAYMENT_STATUS: DropDownItem[] = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]

export const MEMBER_STATUS: DropDownItem[] = [
  { label: 'Suspended / On-Hold', value: 'suspend' },
  { label: 'Cancelled / Terminated', value: 'cancel' },
  { label: 'Registered / Current', value: 'registered' },
]

export const MEMBER_GENDER: DropDownItem[] = [
  { label: 'Male', value: '1' },
  { label: 'Female', value: '2' },
]
