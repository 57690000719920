import { useState } from 'react'
import { useNavigate } from 'react-router'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import Table from 'components/Table'
import { CampaignListTableTypes } from 'schemas/campaign-schemas'
import { ROUTES } from 'constants/routing'

const columns = ['Identifier', 'Merchant Name', 'Merchant Code', 'External Id']

export const CampaignListTable = (props: CampaignListTableTypes) => {
  const navigate = useNavigate()

  const { tableData } = props
  const [currentPage, setCurrentPage] = useState(1)

  function handlePageClick({ selected }: { selected: number }): void {
    // setLoading(true)
    // setOffset(selected * perPage)
    setCurrentPage(selected + 1)
  }

  return (
    <Table
      data={tableData}
      columns={columns}
      pageCount={0}
      showActions
      currentPage={currentPage}
      perPage={10}
      onPageChange={handlePageClick}
      itemCount={tableData.length}
    >
      {tableData.map((campaign) => (
        <tr
          key={campaign.campaign_id}
          className="py-2 text-black bg-white cursor-pointer hover:bg-gray-600"
          // redirect to merchant profile
          onClick={() =>
            navigate(
              `${ROUTES.Merchant.path}/${campaign.campaign_id}/transactions`
            )
          }
        >
          <td className="py-2 pl-6">
            <p>{campaign.campaign_id}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{campaign.campaign_id}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{campaign.campaign_id}</p>
          </td>
          <td className="py-2 pl-6">
            <p>{campaign.campaign_id}</p>
          </td>
          <td className="float-right py-2 pl-6 mr-4">
            <Icon icon={faChevronRight} />
          </td>
        </tr>
      ))}
    </Table>
  )
}
