import { permissionMap } from './permissions'

export const ROUTES = {
  Login: {
    path: '/',
    permissionIds: [],
  },
  ForgotPassword: {
    path: '/forgot-password',
    permissionIds: [],
  },
  MemberLookup: {
    path: '/member-lookup',
    permissionIds: [permissionMap.MemberLookup],
  },
  Members: {
    path: '/member-profile',
    permissionIds: [permissionMap.MemberLookup],
  },
  SubsquadLookup: {
    path: '/subsquad-lookup',
    permissionIds: [permissionMap.Subsquad],
  },
  Subsquad: {
    path: '/subsquad-members',
    permissionIds: [permissionMap.Subsquad],
  },
  SubsquadMembers: {
    path: '/subsquad-members/:groupURN/:groupId',
    permissionIds: [permissionMap.Subsquad],
  },
  MerchantLookup: {
    path: '/merchant-lookup',
    permissionIds: [permissionMap.MerchantLookup],
  },
  MerchantLookupSubway: {
    path: '/merchant-lookup-subway',
    permissionIds: [permissionMap.MerchantLookup],
  },
  Merchant: {
    path: '/merchant-profile',
    permissionIds: [permissionMap.MerchantLookup],
  },
  MerchantProfile: {
    path: '/merchant-profile/:traderId/*',
    permissionIds: [permissionMap.MerchantLookup],
  },
  MerchantProfileTransactions: {
    path: 'transactions',
    permissionIds: [permissionMap.MerchantProfile, permissionMap.Transactions],
  },
  MerchantProfileIdentification: {
    path: 'identifications',
    permissionIds: [
      permissionMap.MerchantIdentification,
      permissionMap.Identification,
    ],
  },
  MerchantProfileDevice: {
    path: 'devices',
    permissionIds: [
      permissionMap.MerchantProfile,
      permissionMap.MerchantDevice,
    ],
  },
  MerchantProfileMemberships: {
    path: 'memberships',
    permissionIds: [
      permissionMap.MerchantProfile,
      permissionMap.MerchantMemberships,
    ],
  },
  MemberProfile: {
    path: '/member-profile/:traderId/*',
    permissionIds: [permissionMap.MemberLookup],
  },
  MemberProfileDashboard: {
    path: '/',
    permissionIds: [permissionMap.MemberLookup],
  },
  MemberProfileTransactions: {
    path: 'transactions',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Transactions],
  },
  
  HDAReports: {
    path: '/reports/:traderId/*',
    permissionIds: [permissionMap.HDAReports],
  },
  HDAReports_MemberTransactionsReports: {
    path: 'member-transactions-reports',
    permissionIds: [permissionMap.HDAReports, permissionMap.HDAReports_MemberTransactionsReports],
  },
  
  MemberProfileComms: {
    path: 'comms',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Comms],
  },
  MemberProfileCommsMainNZ: {
    path: 'comms_main_nz',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Comms],
  },
  MemberProfileVouchers: {
    path: 'vouchers',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Vouchers],
  },
  MemberProfileOffers: {
    path: 'offers',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Vouchers],
  },
  MemberProfileMemos: {
    path: 'memos',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Memos],
  },
  MemberProfileMemberships: {
    path: 'memberships',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Membership],
  },
  MemberProfileMembershipsSubway: {
    path: 'memberships_subway',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Membership],
  },
  MemberProfileFinancials: {
    path: 'financials',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Financials],
  },
  MemberProfileBankAccounts: {
    path: 'bank_accounts',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Financials],
  },
  MemberProfileDirectPayments: {
    path: 'direct_payments',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Financials],
  },
  MemberProfileIdentification: {
    path: 'identification',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Identification],
  },
  MemberProfileIdentificationHampsta: {
    path: 'identification_hampsta',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Identification],
  },
  MemberProfileIdentificationGeneric: {
    path: 'identification_generic',
    permissionIds: [permissionMap.MemberProfile, permissionMap.Identification],
  },
  MemberProfileHistory: {
    path: 'history',
    permissionIds: [permissionMap.MemberProfile, permissionMap.History],
  },
  SubsquadProfile: {
    path: 'subsquad/:groupId/*',
    permissionIds: [permissionMap.Subsquad],
  },
  SubsquadProfileQTFs: {
    path: 'subsquad/:groupId/qtfs',
    permissionIds: [permissionMap.Subsquad],
  },

  // CAMPAIGN
  CampaignList: {
    path: '/campaign-list',
    permissionIds: [permissionMap.CampaignList],
  },
  CampaignDetail: {
    path: '/campaign-detail/:campaignId/*',
    permissionIds: [permissionMap.CampaignDetail],
  },

}
