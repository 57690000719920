import { useQuery } from 'react-query'

import { getCampaignList } from 'api/campaign'
import { CampaignParamsTypes } from 'schemas/campaign-schemas'

import { QUERY_KEYS } from 'constants/querykeys'

export const useCampaignList = (params: CampaignParamsTypes) => {
  const queryKey = [QUERY_KEYS.CAMPAING_LIST, params]

  const queryFn = async () => {
    return await getCampaignList()
  }

  const query = useQuery({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn,
  })

  return query
}
