import { http } from 'utils'

import {
  CampaignAPISchemas,
  CampaignAPITypes,
  CampaignParamsTypes,
  CampaignCreateFormSchemas,
  CampaignCreateFormTypes
} from 'schemas/campaign-schemas'

export const getCampaignList = async() => {
  // let url = ``
  // const params = new URLSearchParams()

  // url += `?${params.toString()}`

  //   const response = await http.get(url)
  //   const result = CampaignAPISchemas.safeParse(response);

  //   if (!result.success) {
  //     throw new Error (JSON.stringify(result.error))
  //   }

  //return result.data

  await new Promise(resolve => setTimeout(resolve, 1000));

    const x:CampaignAPITypes = {
      outcomeCode: "",
      outcomeMessage: "",
      outcomeUserMessage: "",
      data: [
        {campaign_id: "1", code: "1" , external_reference: "1", name: "CAMP1", description: "campaign 1", startDate: "01/01/2025", endDate: "01/01/2025", status: "active" },
        {campaign_id: "2", code: "2" , external_reference: "2", name: "CAMP2", description: "campaign 2", startDate: "01/01/2025", endDate: "01/01/2025", status: "active" },
        {campaign_id: "3", code: "3" , external_reference: "3", name: "CAMP3", description: "campaign 3", startDate: "01/01/2025", endDate: "01/01/2025", status: "active" },
        {campaign_id: "4", code: "4" , external_reference: "4", name: "CAMP4", description: "campaign 4", startDate: "01/01/2025", endDate: "01/01/2025", status: "active" },
        {campaign_id: "5", code: "5" , external_reference: "5", name: "CAMP5", description: "campaign 5", startDate: "01/01/2025", endDate: "01/01/2025", status: "active" }
      ]
    }

    
}


 
 export const postCampaignCreate = async (
   formdData: CampaignCreateFormTypes
 ) => {
   
     const path = ``;
     const response = await fetch(`${path}`, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
         // Authorization: `Bearer ${token}`,
       },
       body: JSON.stringify(formdData),
     });
 
     if (!response.ok) {
       const errorData = await response.json();
       const parseResult =
       CampaignAPISchemas.safeParse(errorData);
 
       if (parseResult.data?.outcomeCode !== "0") {
         throw new Error("Something went wrong.");
       }
 
       if (parseResult.success) {
         return parseResult.data;
       }
 
       // Either parsing failed or it's a different error
       throw new Error(errorData.message || response.statusText);
     }
 
     const results = await response.json();
     const parseResult = CampaignCreateFormSchemas.safeParse(results);
 
     if (!parseResult.success) {
       console.log(parseResult.error);
       throw new Error("Invalid response format");
     }
 
     return parseResult.data;
   
 };
 