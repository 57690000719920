import NoResults from 'components/NoResults'
import Spinner from 'components/Spinner'
import { isEmpty } from 'lodash'

import { CampaignListTable } from './campaign-list-table'
import { useCampaignList } from './use-campaign-list'
import { CampaignListActionsModal } from './campaign-list-actions-modal'

export const CampaignList = () => {
  const { data: dataCampaignList, isLoading: isLoadingCampaignList } =
    useCampaignList({})

  return (
    <div className="p-12 bg-white content-container">
      <div className="flex flex-col lg:flex-row lg:justify-between gap-2">
        <div>
          <h3>
            <span className="tracking-wider font-bold text-xl">Campaign</span>
          </h3>
          <p>
            <span className="tracking-wider font-light">
              Check the list of available campaign
            </span>
          </p>
          <br />
          <p>
            <span className="tracking-wider font-light">
              or create a new campaign
            </span>
          </p>
          <br />
          <p>
            <CampaignListActionsModal />
          </p>
        </div>
      </div>

      <div className="my-6">
        {isLoadingCampaignList ? (
          <Spinner />
        ) : dataCampaignList == null ? (
          <></>
        ) : isEmpty(dataCampaignList) ? (
          <NoResults noImage />
        ) : (
          <CampaignListTable tableData={[dataCampaignList]} />
        )}
      </div>
    </div>
  )
}
